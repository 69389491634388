import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { configActions } from '../../../store/config';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './AppointmentCreated.module.css';
import ThanksYou from '../../../UI/ThanksYou/ThanksYou';
import DetailInfoItem from '../../../UI/DetailInfoItem/DetailInfoItem';
import CancelButton from '../../../UI/CancelButton/CancelButton';
import CancelHeader from '../../../components/Header/CancelHeader/CancelHeader';
import { getDateFormat, getDateFormatNoTZUTC, getFomatDateShort, getFomatTime } from '../../../utils/Utils';
import { getLabelFromValue } from './../../../services/data';
import ConfirmButton from '../../../UI/ConfirmButton/ConfirmButton';

const AppointmentCreated = ({ userData = {}, isCalendar = false }) => {
  const { storeLabel, startTime, endTime, selectedTimeZone, country, email, salutation, firstName, lastName, countryCode, mobile, subject, comment, modality, landingPage, timeZone } = userData;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const fullName = t(salutation) + ' ' + firstName + ' ' + lastName;
  const phone = landingPage !== 'cancel' ? countryCode + ' ' + mobile : mobile;
  const { clientEmail, clientName, clientPhone } = useSelector((state) => state.config.clientInfos);
  const isAuth = useSelector((state) => state.config.isAuth);
  const name = isAuth ? clientName : fullName;
  const currentLng = useSelector((state) => state.config.lng);
  const modalityLabel = getLabelFromValue(modality);
  const formatTime = getFomatTime(currentLng);
  const formatDate = getFomatDateShort(currentLng);
  const dateLabel = landingPage === 'cancel' || landingPage === 'calendar' ? getDateFormat(startTime, formatDate, timeZone) : getDateFormatNoTZUTC(startTime, formatDate);
  const timeLabel = landingPage === 'cancel' || landingPage === 'calendar' ? getDateFormat(startTime, formatTime, timeZone) + ' - ' + getDateFormat(endTime, formatTime, timeZone) : getDateFormatNoTZUTC(startTime, formatTime) + ' - ' + getDateFormatNoTZUTC(endTime, formatTime);
  const optionsList = [
    { title: 'FULL NAME', value: name || '-' },
    { title: 'EMAIL', value: isAuth ? clientEmail : email || '-' },
    { title: 'PHONE', value: isAuth ? clientPhone : phone || '-' },
    { title: 'REASON', value: subject || '-' },
    comment ? { title: 'COMMENTS', value: comment || '-' } : null,
    { title: 'MODALITY', value: modalityLabel || '-' },
    { title: 'STORE', value: `${storeLabel} (${country})` || '-' },
    { title: 'DATE', value: dateLabel || '-' },
    { title: 'TIME', subtitle: `- ${selectedTimeZone}`, value: timeLabel || '-' }
  ].filter(item => item !== null);
  const isDataEmpty = Object.keys(userData).length === 0;
  const nameToDisplay = isDataEmpty ? '' : isAuth ? clientName : firstName;
  const noDataBody = 'Thank you for making an appointment, you will receive a confirmation email soon.';

  useEffect(() => {
    dispatch(configActions.setContactDetailsParams({}));
  }, [dispatch]);

  const backToAppointmentsListClicked = useCallback(() => {
    history.push({
      pathname: '/appointmentList'
    });
  }, [history]);

  const backToCreateAppointmentsClicked = useCallback(() => {
    history.push({
      pathname: '/'
    });
  }, [history]);

  return <div className={styles.container}>
    {landingPage === 'cancel' ? <div><CancelHeader /></div> : <ThanksYou clientName={nameToDisplay} subtitle={isCalendar ? 'We are looking forward to meeting you' : undefined} />}
    <div className={styles.containerInfosDetails}>
      <div className={styles.containerInfosDetailsTitle}>
        <p className={styles.containerInfosDetailsTitleLabel}>{t(landingPage === 'cancel' ? 'APPOINTMENT DETAILS' : 'APPOINTMENT CONFIRMATION')}</p>
      </div>
      <div className={styles.containerInfosDetailsTitleLine}>
      </div>
      {isDataEmpty ?
        <>
          <div className={styles.noDataTitleLabel}><p>{t(noDataBody)}</p></div>
          <ConfirmButton title={t("BOOK A NEW APPOINTMENT")} handleButtonClicked={backToCreateAppointmentsClicked} />
        </>
        :
        optionsList && optionsList.length > 0 && optionsList.map(item => <DetailInfoItem key={item.title} title={item.title} subtitle={item.subtitle} value={item.value} />)}
    </div>
    {isAuth && <CancelButton title={t('VIEW ALL MY APPOINTMENTS')} handleButtonClicked={backToAppointmentsListClicked} />}
  </div>;
};

export default React.memo(AppointmentCreated);
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientInfos } from '../services/salesforce/index';
import { getUrlParams } from '../utils/Utils';
import { configActions } from './../store/config';
import { setEvent } from './../utils/firebaseUtils';
import { langMappings } from './../services/data';
import { setLocalDate } from './../utils/Utils';
import i18n from "i18next";

export const initializeApp = createAsyncThunk('app/initialize', async (_, { dispatch }) => {
    try {
        const key = getUrlParams();
        const response = await getClientInfos(key);
        const data = response.data;
        console.log("response: ", response);
        if (response.success === true) {
            if (data && data.status === 'tokenExpired') {
                dispatch(configActions.setIsSessionExpired(true));
            }
            if (data && data.status === 'clientDoesntExist') {
                dispatch(configActions.setIsSessionExpired(true));
            }
            if (data && data.status === 'cancelAlreadyDone') {
                const lng = langMappings[data.clientLang] || data.clientLang || 'en_US';
                setLocalDate(lng);
                dispatch(configActions.setCurrentLanguage(lng));
                i18n.changeLanguage(lng, (err, t) => {
                    if (err) return console.log('something went wrong loading', err);
                });
                dispatch(configActions.setClientInfos(data));
                dispatch(configActions.setIsSessionExpired(true));
            }
            else if (data.status === 'tokenValid') {
                const lng = langMappings[data.clientLang] || data.clientLang || 'en_US';
                setLocalDate(lng);
                dispatch(configActions.setCurrentLanguage(lng));
                i18n.changeLanguage(lng, (err, t) => {
                    if (err) return console.log('something went wrong loading', err);
                });
                const isLogged = data.clientId ? true : false;
                const status = isLogged ? 'BAA_user_is_logged_in' : 'BAA_user_is_guest';
                setEvent(status);
                dispatch(configActions.setIsAuth(isLogged));
                dispatch(configActions.setClientInfos(data));
                dispatch(configActions.setIsSessionExpired(false));
            }
            else {
                dispatch(configActions.setIsSessionExpired(true));
            }
        }
        dispatch(configActions.setIsAppInitialized(true));
    } catch (error) {
        console.log('error: ', error);
        dispatch(configActions.setIsAppInitialized(true));
    }
});


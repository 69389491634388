import React, { useEffect } from 'react';
import styles from './AppointmentResume.module.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import SessionExpired from '../components/SessionExpired/SessionExpired';
import HeaderLogged from '../components/Header/HeaderLogged';
import HeaderGuest from '../components/Header/HeaderGuest';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';
import AppointmentCreated from './../components/CreateAppointment/AppointmentCreated/AppointmentCreated';
import { setEvent } from './../utils/firebaseUtils';

const AppointmentResume = () => {

    const history = useHistory();
    const isAuth = useSelector((state) => state.config.isAuth);
    const isSessionExpired = useSelector((state) => state.config.isSessionExpired);
    const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
    const appointmentData = history.location.state;

    useEffect(() => {
        if (appointmentData && !appointmentData.accountId) {
            setEvent('BAA_appointment_summary');
        }
    }, []);

    return <>
        <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
            <HeaderMobile clientLanguage={clientLanguage} />{isAuth ? <HeaderLogged clientLanguage={clientLanguage} /> : <HeaderGuest clientLanguage={clientLanguage} />}
            {!isSessionExpired ? <div className={clientLanguage === "ar" ? styles.containerResumeRTL : styles.containerResumeLTR}>
                <AppointmentCreated userData={appointmentData} />
            </div>
                : <SessionExpired />}
        </div>
        <div>
            <Footer />
        </div>
    </>
};

export default AppointmentResume;
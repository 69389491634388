import React from 'react';
import styles from './ModalSureToDelete.module.css';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getDateFormat, getFomatDate, getFomatTime } from './../../../utils/Utils';
import closeCross from './../../../styles/Images/appointementListDesktop/closeCross.png';

const ModalSureToDelete = ({ onClose, open, confirmCancelAppointment, selectedAppointment, clientLanguage }) => {
  const screenWidth = window.innerWidth;
  const { t } = useTranslation();
  const schedStartDate = new Date(selectedAppointment.startDateTime);
  const appointmentType = selectedAppointment.appointmentType;
  const format = getFomatDate(clientLanguage);
  const formatTime = getFomatTime(clientLanguage);
  const options = {
    'PHONE CALL': 'phone',
    'VIDEO SESSION': 'video',
  };
  const label = t("You are about to cancel your") + ' ' + options[appointmentType] + ' ' + t("appointment on");
  const LabelInPerson = t("You are about to cancel your appointment on");
  const infosLabelInPerson = getDateFormat(schedStartDate, format, selectedAppointment.timeZone) + ' ' + t("at") + ' ' + getDateFormat(schedStartDate, formatTime, selectedAppointment.timeZone) + ' ' + t("at") + ' ' + selectedAppointment.storeName;
  const infosLabel = getDateFormat(schedStartDate, format, selectedAppointment.timeZone) + ' ' + t("at") + ' ' + getDateFormat(schedStartDate, formatTime, selectedAppointment.timeZone) + '.';

  return <div>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='md'
      fullWidth={true}
      fullScreen={screenWidth < 500 ? true : false}
      PaperProps={{
        style: { borderRadius: 0 }
      }}
    >
      <div className={clientLanguage === "ar" ? styles.closeCrossContainerRTL : styles.closeCrossContainerLTR}>
        <img src={closeCross} alt="close" onClick={onClose} className={clientLanguage === "ar" ? styles.crossIconRTL : styles.crossIconLTR}></img>
      </div>
      <DialogTitle id="alert-dialog-title">
        <div className={styles.headerContainer}>
          <p>{t("CANCEL APPOINTMENT")}</p>
        </div>
      </DialogTitle>
      <div className={styles.bodyContainer}>
        <p className={styles.bodyContainerLabel}>{appointmentType === 'PEOPLE IN-PERSON' ? LabelInPerson : label}</p>
        <p className={styles.bodyContainerLabelInfos}>{appointmentType === 'PEOPLE IN-PERSON' ? infosLabelInPerson : infosLabel}</p>
        <p className={styles.bodyContainerLabel}>{t("Do you want to proceed?")}</p>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonTopStyle} onClick={() => confirmCancelAppointment(selectedAppointment)}>
          <p className={styles.buttonTopStyleLabel}>{t("CANCEL APPOINTMENT")}</p>
        </div>

      </div>
    </Dialog>
  </div>
}

export default React.memo(ModalSureToDelete);
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AppRoutes from './../../router/routes';
import { useHistory } from 'react-router-dom';
import LoadingPage from './../../pages/LoadingPage';
import SessionExpired from './../../components/SessionExpired/SessionExpired';
import useLanguageChangedListenerInit from './../../hooks/useLanguageChangedListenerInit';
import styles from './AppContainer.module.css';
import HeaderLogged from './../../components/Header/HeaderLogged';
import HeaderGuest from './../../components/Header/HeaderGuest';
import HeaderMobile from './../../components/Header/HeaderMobile/HeaderMobile';
import Footer from './../../components/Footer/Footer';
import { langMappings } from './../../services/data';

const AppContainer = () => {
    const history = useHistory();
    const isAppInitialized = useSelector((state) => state.config.isAppInitialized);
    const { landingPage, clientLang } = useSelector((state) => state.config.clientInfos);
    const { isAuth, isSessionExpired, lng } = useSelector((state) => state.config);
    const navigationType = useRef(null);
    const [isLoadingLanguage, setIsLoadingLanguage] = useState(true);

    useLanguageChangedListenerInit((lang) => {
        if (lang === clientLang || lang === langMappings[clientLang]) {
            setIsLoadingLanguage(false);
        }
    });

    useEffect(() => {
        if (isAppInitialized === true && !isLoadingLanguage) {
            const navigationEntries = performance.getEntriesByType('navigation');
            if (navigationEntries.length > 0) {
                navigationType.current = navigationEntries[0].type;
            }
            if (landingPage && navigationType.current !== 'reload') {
                switch (landingPage) {
                    case 'create':
                        history.push({
                            pathname: '/'
                        });
                        break;
                    case 'manage':
                        const path = isAuth ? '/appointmentList' : '/'
                        history.push({
                            pathname: path
                        });
                        break;
                    case 'cancel':
                        history.push({
                            pathname: '/appointmentCancel'
                        });
                        break;
                    case 'calendar':
                        history.push({
                            pathname: '/addtocalendar'
                        });
                        break;
                    default:
                        history.push({
                            pathname: '/'
                        });
                        break;
                }
            }
        }
    }, [landingPage, history, isAppInitialized, isAuth, isLoadingLanguage, clientLang]);

    if (isAppInitialized === true && !isLoadingLanguage && !isSessionExpired) {
        return <div className="App">
            <AppRoutes />
        </div>;
    }
    else if (isAppInitialized === true && isSessionExpired) {
        return <><div className={clientLang === "ar" ? styles.containerRTL : styles.containerLTR}>
            <HeaderMobile clientLanguage={clientLang} />{isAuth ? <HeaderLogged clientLanguage={clientLang} /> : <HeaderGuest clientLanguage={clientLang} />}
            <SessionExpired errorMsg={landingPage === 'cancel' ? 'Your appointment has already been canceled' : undefined} />
        </div>
            <div>
                <Footer />
            </div></>;
    }
    else {
        return <LoadingPage />;
    }
};

export default AppContainer;

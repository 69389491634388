import { createSlice } from '@reduxjs/toolkit';

const initialConfigState = {
    isAppInitialized: false,
    lng: 'en_US',
    clientInfos: {},
    isAuth: false,
    isSessionExpired: true,
    appointmentParams: {},
    contactDetailsParams: {},
    cancelAppointmentParams: {}
};

const configSlice = createSlice({
    name: 'config',
    initialState: initialConfigState,
    reducers: {
        setClientLanguage(state, action) {
            state.lng = action.payload;
        },
        setClientInfos(state, action) {
            state.clientInfos = action.payload;
        },
        setIsAuth(state, action) {
            state.isAuth = action.payload;
        },
        setCurrentLanguage(state, action) {
            state.lng = action.payload;
        },
        setIsSessionExpired(state, action) {
            state.isSessionExpired = action.payload;
        },
        setAppointmentParams(state, action) {
            state.appointmentParams = action.payload;
        },
        setContactDetailsParams(state, action) {
            state.contactDetailsParams = action.payload;
        },
        setCancelAppointmentParams(state, action) {
            state.cancelAppointmentParams = action.payload;
        },
        setIsAppInitialized(state, action) {
            state.isAppInitialized = action.payload;
        }
    }
});

export const configActions = { ...configSlice.actions };

export default configSlice.reducer;
